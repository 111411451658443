<template>
  <div>
    <div class="search">
      <div class="title">分类列表</div>
      <hr />
      <el-form :inline="true" class="top">
        <el-form-item label="分类名称:">
          <el-input v-model="params.nickName"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="getList(1)">查询</el-button>
          <el-button type="primary" @click="add">添加</el-button>
          <el-button type="primary" v-if="params.fatherId != 0" @click="back"
            >返回</el-button
          >
          <!-- <el-button type="primary" @click="reset">重置</el-button> -->
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData"
      border
      tooltip-effect="dark"
      :row-key="rowKey"
      style="width: 100%"
      @selection-change="handleSelectionChange"
      align="center"
    >
      <el-table-column
        type="selection"
        width="55"
        :reserve-selection="true"
        align="center"
      >
      </el-table-column>
      <!-- <el-table-column type="index" width="50" align="center"> </el-table-column> -->
      <el-table-column prop="id" label="ID" align="center">
      </el-table-column>
      <el-table-column label="排序" align="center" width="80">
        <template slot-scope="scope">
          <el-input v-model="scope.row.dept" @change="changeSort(scope.row)"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="fatherName" label="上级名称" align="center">
      </el-table-column>
      <el-table-column prop="cateName" label="分类名称" align="center"> </el-table-column>
      <el-table-column label="展示图 " width="120" align="center">
        <template slot-scope="scope">
          <el-image
            style="width: 70px; height: 70px"
            :src="scope.row.cateImg"
            :preview-src-list="[scope.row.cateImg]"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="statusStr" label="状态" width="120" align="center">
      </el-table-column>
      <el-table-column label="展示类型" width="100" align="center">
        <template slot-scope="{ row }">
          <div>
            {{ row.isProd == 1 ? "产品" : "分类" }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="addTime" label="创建时间" width="200" align="center">
      </el-table-column>
      <el-table-column label="操作" align="center" width="300">
        <template slot-scope="scope">
          <el-button
            type="success"
            size="mini"
            v-if="scope.row.status == 2"
            @click="upData(scope.row, 1)"
            >上架</el-button
          >
          <el-button
            type="warning"
            size="mini"
            v-if="scope.row.status == 1"
            @click="upData(scope.row, 2)"
            >下架</el-button
          >
          <el-button type="primary" size="mini" @click="upData(scope.row, 0)"
            >删除</el-button
          >
          <el-button type="primary" size="mini" @click="edit(scope.row)">编辑</el-button>
          <el-button
            type="primary"
            v-if="params.fatherId == 0"
            size="mini"
            @click="toSun(scope.row)"
            >查看下级</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <!-- <div>
              <el-button type="primary" @click="allCheckVip">批量修改vip</el-button>
            <el-button type="primary" @click="allOpe">批量操作</el-button>
         </div> -->
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="params.currentPage"
          :page-sizes="[5, 10, 20]"
          :page-size="params.pageSize"
          layout="total,sizes,prev,pager,next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="添加/修改"
      :visible.sync="dialogVisible"
      width="30%"
      :close-on-click-modal="false"
    >
      <el-form :model="addList" :rules="addRules" ref="addRuleFormRef">
        <el-form-item label="分类名称:" prop="cateName">
          <el-input v-model="addList.cateName"></el-input>
        </el-form-item>
        <el-form-item label="展示图" prop="showImg">
          <send-image
            type="one"
            :images="addList.showImg"
            @addImageStr="addImageStr"
          ></send-image>

          <span style="color: red; margin-left: 20px"
            >每张图片不能超过500K，建议尺寸：172*115px</span
          >
        </el-form-item>
        <el-form-item label="分类展示页" prop="isProd">
          <el-radio v-model="addList.isProd" :label="1">产品</el-radio>
          <el-radio v-model="addList.isProd" :label="2">分类</el-radio>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  foodCateList,
  foodCateListAdd,
  foodCateListSort,
  foodCateListUpdata,
} from "../../api/food.js";
import SendImage from "../../components/sendImage.vue";
export default {
  components: {
    SendImage,
  },
  data() {
    return {
      dialogVisible: false,
      total: 0,
      params: {
        currentPage: 1,
        pageSize: 5,
        fatherId: 0,
        cateName: "",
      },
      addList: {
        cateId: 0,
        cateName: "",
        showImg: "",
        fatherId: 0,
        isProd: 1,
      },
      addRules: {
        showImg: [{ required: true, message: "请上传展示图", trigger: "blur" }],
        cateName: [{ required: true, message: "请输入分类名称", trigger: "blur" }],
      },
      tableData: [],
      date: null,
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6;
        },
      },
    };
  },
  created() {
    this.getList();
  },
  //   props: {
  //     id: {
  //       type: [String, Number],
  //       default: 0,
  //     },
  //   },
  //   watch: {
  //     id: {
  //       handler(val) {
  //         console.log(val);
  //         // this.params.fatherId = val;
  //         // this.getList();
  //       },
  //       immediate: true,
  //     },
  //   },
  methods: {
    rowKey(row) {
      return row.id;
    },
    handleSelectionChange(val) {
      //   this.multipleSelection = val;
    },
    addImageStr(e) {
      this.addList.showImg = e;
    },
    async getList(page = "") {
      if (page) {
        this.params.currentPage = page;
      }
      //   this.params.beginTime = this.date ? this.date[0] : "";
      //   this.params.endTime = this.date ? this.date[1] : "";
      const { data } = await foodCateList(this.params);
      if (data.code == 0) {
        this.tableData = data.list;
        this.total = data.pagination.total;
      } else {
      }
    },
    handleSizeChange(e) {
      this.params.pageSize = e;
      this.getList();
    },
    // 页数改变
    handleCurrentChange(e) {
      this.params.currentPage = e;
      this.getList();
    },
    reset() {
      this.params.currentPage = 1;
      this.params.pageSize = 5;
      this.params.cateName = "";

      this.date = null;
      this.getList();
    },
    changeSort(row) {
      foodCateListSort({
        dept: row.dept,
        cateId: row.id,
      }).then((res) => {
        if (res.data.code == 0) {
          this.$message.success("设置成功");
          this.getList();
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
    back() {
      this.params.fatherId = 0;
      this.addList.fatherId = 0;
      //   this.$router.push({ path: "/foodCateList" });
      //   this.showback = false;
      this.getList(1);
    },
    toSun(row) {
      //   this.$router.push({
      //     path: `/foodCateList/${row.id}`,
      //   });
      //   this.showback = true;
      this.params.fatherId = Number(row.id);
      this.addList.fatherId = Number(row.id);
      this.getList(1);
    },
    upData(row, status) {
      let str = "";
      switch (status) {
        case 1:
          str = "上架";
          break;
        case 2:
          str = "下架";
          break;
        case 0:
          str = "删除";
          break;
      }

      this.$confirm(`此操作将${str}该分类, 是否继续?, 提示`, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          foodCateListUpdata({
            status,
            cateId: row.id,
          }).then((res) => {
            if (res.data.code == 0) {
              this.$message.success("操作成功");

              this.getList();
            } else {
              this.$message.warning(res.data.msg);
            }
          });
        })
        .catch(() => {});
    },
    add() {
      this.addList.cateId = 0;
      this.addList.cateName = "";
      this.addList.showImg = "";
      this.addList.isProd = 1;

      this.dialogVisible = true;
    },
    edit(row) {
      this.addList = {
        cateId: row.id,
        cateName: row.cateName,
        showImg: row.cateImg,
        fatherId: row.fatherId,
        isProd: row.isProd,
      };
      this.dialogVisible = true;
    },
    submitForm() {
      this.$refs.addRuleFormRef.validate(async (valid) => {
        if (valid) {
          if (this.load) return;
          this.load = true;
          const { data } = await foodCateListAdd(this.addList);
          this.load = false;
          if (data.code == 0) {
            this.$message.success("操作成功");
            this.dialogVisible = false;
            this.getList();
          } else {
            this.$message.warning(data.msg);
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.title {
  padding: 10px 0;
}
.top {
  margin-top: 20px;
}
.block {
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
</style>
